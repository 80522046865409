<template>
  <b-modal
    :visible="isModalActive"
    title="Api Setting"
    ok-title="Accept"
    @change="(val) => $emit('update:is-modal-active', val)"
  >
    <b-row class="mt-1">
      <b-card-text class="ml-2 mr-2">
        apiWebScraping :
      </b-card-text>
      <b-form-checkbox
        :checked="apiWebScraping
        "
        class="custom-control-primary"
        name="check-button"
        switch
        @change="switchChange('apiWebScraping', $event)"
      />
    </b-row>
    <b-row class="mt-1">
      <b-card-text class="ml-2 mr-2">
        apiQRcode :
      </b-card-text>
      <b-form-checkbox
        :checked="apiQRcode
        "
        class="custom-control-primary"
        name="check-button"
        switch
        @change="switchChange('apiQRcode', $event)"
      />
    </b-row>
    <b-row class="mt-1">
      <b-card-text class="ml-2 mr-2">
        apiIBanking :
      </b-card-text>
      <b-form-checkbox
        :checked="apiIBanking
        "
        class="custom-control-primary"
        name="check-button"
        switch
        @change="switchChange('apiIBanking', $event)"
      />
    </b-row>
    <b-row class="mt-1">
      <b-card-text class="ml-2 mr-2">
        apiMobileBanking :
      </b-card-text>
      <b-form-checkbox
        :checked="apiMobileBanking
        "
        class="custom-control-primary"
        name="check-button"
        switch
        @change="switchChange('apiMobileBanking', $event)"
      />
    </b-row>
    <b-row class="mt-1">
      <b-card-text class="ml-2 mr-2">
        apiCreditCard :
      </b-card-text>
      <b-form-checkbox
        :checked="apiCreditCard
        "
        class="custom-control-primary"
        name="check-button"
        switch
        @change="switchChange('apiCreditCard', $event)"
      />
    </b-row>
    <b-row class="mt-1">
      <b-card-text class="ml-2 mr-2">
        apiATM :
      </b-card-text>
      <b-form-checkbox
        :checked="apiATM
        "
        class="custom-control-primary"
        name="check-button"
        switch
        @change="switchChange('apiATM', $event)"
      />
    </b-row>
    <template #modal-footer>
      <div class="w-100" />
    </template>
  </b-modal>
</template>

<script>

import {
  BModal,
  BRow,
  BCol,
  BCardText,
  BFormCheckbox,
  BButton,

} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    BCardText,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,

  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isModalActive',
    event: 'update:is-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      apiWebScraping: false, // สถานะการทำ api สำหรับ web Scraping
      apiQRcode: false, // สถานะการทำ api สำหรับ genQRcode
      apiIBanking: false, // สถานะการทำ api สำหรับ ทำ iBanking
      apiMobileBanking: false, // สถานะการทำ api สำหรับ ทำ mobileBanking
      apiCreditCard: false, // สถานะการทำ api สำหรับ ทำ creditCard
      apiATM: false, // สถานะการทำ api สำหรับ ทำ atm
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

  },
  watch: {
    isModalActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
        } else {
          const {
            _id, apiWebScraping, apiQRcode, apiIBanking, apiMobileBanking, apiCreditCard, apiATM,
          } = this.data
          this.dataId = _id
          this.apiWebScraping = apiWebScraping
          this.apiQRcode = apiQRcode
          this.apiIBanking = apiIBanking
          this.apiMobileBanking = apiMobileBanking
          this.apiCreditCard = apiCreditCard
          this.apiATM = apiATM
        }
      }
    },
  },
  methods: {
    initValues() {
      this.dataId = null
      this.apiWebScraping = ''
      this.apiQRcode = ''
      this.apiIBanking = ''
      this.apiMobileBanking = ''
      this.apiCreditCard = ''
      this.apiATM = ''
    },
    switchChange(name, status) {
      
      const obj = {
        id: this.dataId,
        name,
        status,
      }
      store
        .dispatch(`${this.storeModuleName}/switchChange`, obj)
        .then(result => {
          
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
